import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

interface KeyPlayersItem {
  name: string;
  desp: string;
}

interface ThemesReportSectionProps {
  section: string;
  content: string | string[] | KeyPlayersItem[];
  sectionType: "text" | "list" | "key players";
}

const formatTitle = (section: string) => {
  return section
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const ThemesReportSection: React.FC<ThemesReportSectionProps> = ({
  section,
  content,
  sectionType,
}) => {
  const title = formatTitle(section);

  let contentTemplate = <></>;
  if (content?.length) {
    switch (sectionType) {
      case "text":
        contentTemplate = (
          <div className="leading-loose">{content as string}</div>
        );
        break;
      case "list":
        contentTemplate = (
          <ul className="list-inside list-disc">
            {(content as string[])?.map((item, index) => (
              <li key={index} className="py-1.5">
                {item}
              </li>
            ))}
          </ul>
        );
        break;
      case "key players":
        contentTemplate = (
          <ul className="list-inside list-disc space-y-3">
            {(content as KeyPlayersItem[]).map((item) => (
              <li key={item.name}>
                <h3 className="inline py-1.5 font-semibold text-gray-800">
                  {item.name}
                </h3>
                <p className="mt-0.5 py-1.5 leading-relaxed">{item.desp}</p>
              </li>
            ))}
          </ul>
        );
    }
  }

  return (
    <Disclosure defaultOpen as="div">
      {({ open = true }) => (
        <>
          <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
            <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
              {title}
            </h2>
            <div className="flex">
              <span className="mt-1 ml-6 flex h-6 items-center">
                {open ? (
                  <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
                )}
              </span>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel
            as="div"
            className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-relaxed"
          >
            {contentTemplate}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default ThemesReportSection;
