import React from "react";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  ThemesDocumentAttributes,
} from "../../types/cms";
import { useAuthContext } from "../../contexts/authContext";
import {
  getFormattedDate,
  getThemesDocumentsListQueryParamsFields,
} from "../../utils";
import { AnimatePresence, motion } from "framer-motion";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ReportDisclaimer, SunshineSupport } from "../../constants";
import ThemesReportSection from "./ThemesReportSection";
import useNorthstar from "../../hooks/northstarHook";
import ThemesReportCard from "../ThemesReportCard";

interface ThemesReportViewerProps {
  report: ThemesDocumentAttributes;
}

const ThemesReportViewer: React.FC<ThemesReportViewerProps> = ({ report }) => {
  const { accessToken, clearIdentity } = useAuthContext();

  const { report_date, basket, basket_name } = report;

  const fields = getThemesDocumentsListQueryParamsFields();
  const {
    data: reportsData,
    isLoading,
    error,
  } = useNorthstar<
    CMSAPIDataCollection<CMSAPIDataItem<ThemesDocumentAttributes>>
  >(
    `/api/themes-documents`,
    accessToken,
    {
      method: "get",
    },
    {
      fields,
      filters: {
        basket: {
          $eq: basket,
        },
      },
      sort: { report_date: "desc" },
    },
  );
  const relatedDocuments = reportsData?.data?.length
    ? reportsData?.data?.filter(
        (x) => x.attributes.report_id !== report.report_id,
      )
    : [];

  const sections = [
    "summary",
    "key_players",
    "catalysts",
    "change_in_market_trends",
    "competitive_landscape",
    "growth_drivers",
    "regulatory_environment",
    "risks_and_challenges",
    "technological_advancements",
  ];

  const reportDate = getFormattedDate(report_date);

  const getSectionType = (section: string) => {
    if (section === "summary") {
      return "text";
    } else if (section === "key_players") {
      return "key players";
    } else {
      return "list";
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        key="report-viewer"
        className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 
      border-t-4 bg-white border-white border-t-bam-alternative-blue"
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          stiffness: 50,
          duration: 0.4,
        }}
      >
        <div className="my-3 pl-2 pr-8 sm:pl-4 sm:pr-12 pt-3 pb-2">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
            {basket_name}
          </h1>
          <div className="mt-3 md:mt-4 xl:mt-5 text-sm xs:text-base text-gray-800">
            {reportDate}
          </div>
          <div className="text-bam-blue mt-2 md:mt-3 xl:mt-4">
            <a
              href={`mailto:${SunshineSupport}`}
              className="flex gap-x-1 font-bebas-neue tracking-wide text-lg"
            >
              <div>
                <span className="sr-only">Email</span>
                <EnvelopeIcon className="w-6 h-6 mt-px" />
              </div>
              Contact
            </a>
          </div>
        </div>
        <div className="mt-1 xl:text-lg">
          {sections.map((section) => {
            const content = (report as any)[section];
            if (content?.length) {
              return (
                <ThemesReportSection
                  key={section}
                  section={section}
                  content={content}
                  sectionType={getSectionType(section)}
                />
              );
            }
          })}
        </div>
        <section className="mt-6">
          <h2 className="sr-only">Disclaimer</h2>
          <div className="p-4 pr-10 text-xxs sm:text-xs tracking-tight leading-snug text-bam-medium-gray">
            {ReportDisclaimer}
          </div>
        </section>
      </motion.div>
      {relatedDocuments.length > 0 && (
        <div key="related-reports" className="flex flex-col w-full mt-8">
          <h2 className="font-bebas-neue text-xl text-bam-blue">
            More Reports on {basket_name}:
          </h2>
          <div className="grid grid-cols-1 gap-4 mt-3 sm:grid-cols-2 lg:grid-cols-3">
            {relatedDocuments.map((doc) => (
              <div key={doc.id} className="flex flex-col w-full">
                <ThemesReportCard document={doc.attributes} reportDetailsView />
              </div>
            ))}
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ThemesReportViewer;
